import React from "react";
import { useEffect } from "react";
export default function PrivacyPolicy() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="common-content">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>
          Privacy Policy for PR Jobs Canada At PR Jobs Canada, we take
          your privacy seriously. This Privacy Policy document outlines the
          types of personal information received and collected by PR Jobs Canada and how it is used.
        </p>
        <h1>Information Collection and Use</h1>
        <p>
        PR Jobs Canada collects personal information when users register
          on our site, subscribe to our newsletter, or fill out a form. The
          information collected may include, but is not limited to, name, email
          address, and resume details. We use this information to provide our
          services, personalize user experience, and improve our site.
        </p>
        <h1>Log Files</h1>{" "}
        <p>
          Like many other websites, PR Jobs Canada uses log files. The
          information in these files includes internet protocol (IP) addresses,
          browser types, internet service providers (ISPs), referring/exit
          pages, platform types, date/time stamps, and number of clicks to
          analyze trends, administer the site, track user movement, and gather
          demographic information. IP addresses and other such information are
          not linked to any personally identifiable information.
        </p>{" "}
        <h1>Cookies and Web Beacons </h1>{" "}
        <p>
        PR Jobs Canada uses cookies to store information about user
          preferences, record user-specific information on which pages users
          access or visit, customize web page content based on user browser type
          or other information that the user sends via their browser.
        </p>{" "}
        <h1>Security</h1>{" "}
        <p>
          We are committed to ensuring that your information is secure. We have
          implemented suitable physical, electronic, and managerial procedures
          to safeguard and secure the information we collect online.
        </p>{" "}
        <h1>Sharing of Information</h1>{" "}
        <p>
          We do not sell, trade, or otherwise transfer your personally
          identifiable information to third parties. However, non-personally
          identifiable visitor information may be provided to other parties for
          marketing, advertising, or other uses.
        </p>{" "}
        <h1>Your Consent</h1>{" "}
        <p>By using our site, you consent to our privacy policy.</p>{" "}
        <h1>Changes to This Privacy Policy</h1>
        <p>
          {" "}
          PR Jobs Canada reserves the right to update or change our Privacy
          Policy at any time. Any changes will be posted on this page.{" "}
        </p>
        <h1>Contact Us</h1>
        <p>
          {" "}
          If you have any questions about this Privacy Policy, please contact us
          at <span><a href="mailto:Admin@prjobscanada.com">Admin@prjobscanada.com</a>.</span> 
        </p>
      </div>
    </div>
  );
}
