import axios from "axios";
import { authHeader, authHeaderToken } from "../helpers";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//onst API_BASE_URL = "http://localhost:3001/api";
//const API_BASE_URL = "http://3.131.231.110:34677/api";
export const userService = {
  login,
  logout,
  register,
  update,
  getById,
  userUploads,
  changePassword,
};

async function login(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeaderToken(), "Content-Type": "application/json" },

    body: JSON.stringify(data),
  };

  const response = await fetch(`${API_BASE_URL}/users/login`, requestOptions);
  const user = await handleResponse(response);
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem("user", JSON.stringify(user.user));
  return user;
}

// function login(data) {
//   const token = btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`);

//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": `Basic ${token}`
//     },
//     body: JSON.stringify(data),
//   };

//   return fetch(`${API_BASE_URL}/users/login`, requestOptions)
//     .then(handleResponse)
//     .then((user) => {
//       // store user details and jwt token in local storage to keep user logged in between page refreshes
//       localStorage.setItem("user", JSON.stringify(user.user));

//       return user;
//     });
// }

function logout() {
  localStorage.removeItem("user");
  //window.location.reload(true);
}

async function register(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeaderToken(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  console.log(data);
  const response = await fetch(`${API_BASE_URL}/users/registration`, requestOptions);
  return handleResponse(response);
}

// function register(data) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(data),
//   };
//   console.log(data);
//   return fetch(`${API_BASE_URL}/user/register`, requestOptions).then(
//     handleResponse
//   );
// }

async function update(data) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${API_BASE_URL}/user/${data.id}`, requestOptions);
  return handleResponse(response);
}

async function changePassword(data) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${API_BASE_URL}/user/changepassword/${data.id}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const response = await fetch(`${API_BASE_URL}/users/id/${id}`, requestOptions);
  return handleResponse(response);
}

async function userUploads(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  const response = await fetch(`${API_BASE_URL}/user/uploads`, requestOptions);
  return handleResponse(response);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }

      const error = (data && data) || response;
      return Promise.reject(error);
    }

    return data;
  });
}
// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         //location.reload(true);
//       }

//       const error = (data && data) || response;
//       return Promise.reject(error);
//     } if (response.status >= 200 && response.status < 300) {
//       // Successful response, return the data
//       console.log(data) ;
//       if(data.status != "error"){
//         return data;
//       }else{
//         window.alert("error");
//       }
//     }

//   });
// }
