import React from "react";

export default function UploadInputBox({ data }) {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
  };

  return (
    <div className="row">
      {data.map((name, index) => {
        return (
          <div className="col-lg-6" key={index}>
            <div className="half-field">
              <label>
                {name}:
                {name === "Commercial Lease Agreement" && (
                  <p style={{ fontSize: "12px" }}>
                    {"(Leave blank if not applicable)"}
                  </p>
                )}
              </label>
              <div className="inp-field">
                <label htmlFor={`fileInput_${name}`} className="fileInputLabel">
                  <i className="fa fa-paperclip" aria-hidden="true"></i>
                </label>
                <input
                  id={`fileInput_${name}`}
                  type="file"
                  onChange={handleFileChange}
                  className="fileInput"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
