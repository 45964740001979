import React from "react";
import { useEffect } from "react";
export default function Regulations() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="common-content">
      <div className="container">
        <h1>Regulations For using PR Jobs Canada</h1>
        <p>
          Welcome to PR Jobs Canada. By using this website, you agree to
          comply with the following regulations:
        </p>
        <p>
          Users must be at least 18 years old or the legal age of majority in
          their jurisdiction to use PR Jobs Canada. By accessing our
          services, you confirm that you meet these requirements.
        </p>
        <p>
          Users are prohibited from using PR Jobs Canada for any unlawful
          purpose or in any way that violates local, national, or international
          laws and regulations.
        </p>
        <p>
          Users must not engage in any activity that disrupts the normal
          functioning of the website, including but not limited to hacking,
          introducing viruses or harmful code, or attempting to gain
          unauthorized access to our systems.
        </p>
        <p>
          Users agree not to transmit any content that is defamatory, offensive,
          or infringes upon the rights of others, including intellectual
          property rights.
        </p>
        <p>
          Users are responsible for maintaining the confidentiality of their
          account credentials and for all activities that occur under their
          account. Users must immediately notify PR Jobs Canada of any
          unauthorized use of their account or any other security breaches.
        </p>
        <p>
          PR Jobs Canada retains ownership of all content provided on the
          website, including but not limited to logos, text, graphics, and
          software. Users may not reproduce, distribute, modify, or republish
          any content from PR Jobs Canada without explicit permission from
          us.
        </p>
        <p>
          PR Jobs Canada reserves the right to modify or update these
          regulations at any time. Users will be notified of any significant
          changes, and continued use of the website constitutes acceptance of
          the revised regulations.
        </p>
        <p>
          If you have any questions or concerns regarding these regulations,
          please contact us at{" "}
          <span>
            <a href="mailto:Admin@prjobscanada.com">Admin@prjobscanada.com</a>
            .
          </span>{" "}
          .
        </p>
      </div>
    </div>
  );
}
