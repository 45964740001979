import React from "react";
import { useEffect } from "react";
export default function TermsOfService() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="common-content">
      <div className="container">
        <h2>Terms of Service for PR Jobs Canada</h2>
        <p>
          Welcome to PR Jobs Canada. By accessing and using this website,
          you agree to comply with the following Terms of Service:
        </p>

        <h3>Use of Service</h3>
        <p>
          Users must be at least 18 years old or the legal age of majority in
          their jurisdiction to use PR Jobs Canada.
        </p>
        <p>
          Users agree to use the website in compliance with all applicable laws
          and regulations.
        </p>

        <h3>User Accounts</h3>
        <p>
          Users are responsible for maintaining the confidentiality of their
          account credentials and for all activities that occur under their
          account.
        </p>
        <p>
          Users must provide accurate and updated information when creating an
          account.
        </p>

        <h3>User-Generated Content</h3>
        <p>
          Users are solely responsible for the content they submit to PR Jobs Canada
         , including resumes, job applications, and any other
          information.
        </p>
        <p>
          Users must not submit content that is unlawful, defamatory, offensive,
          or infringes upon the rights of others.
        </p>

        <h3>Intellectual Property</h3>
        <p>
          PR Jobs Canada owns all content available on the website,
          including logos, text, graphics, and software. Users may not
          reproduce, distribute, modify, or republish any content without
          explicit permission.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          PR Jobs Canada is not liable for any damages, losses, or
          liabilities arising from the use or inability to use the website or
          its services.
        </p>

        <h3>Modifications to Service</h3>
        <p>
          PR Jobs Canada reserves the right to modify or discontinue any
          part of the service without notice at any time.
        </p>

        {/* <h3>Governing Law</h3>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of [Your Jurisdiction].
        </p> */}

        <h3>Changes to Terms</h3>
        <p>
          PR Jobs Canada reserves the right to update or change these Terms
          of Service at any time. Users will be notified of any changes, and
          continued use of the website constitutes acceptance of the revised
          terms.
        </p>

        <h3>Contact Information</h3>
        <p>
          If you have any questions or concerns regarding these Terms of
          Service, please contact us at{" "}
          <span>
            <a href="mailto:Admin@prjobscanada.com">Admin@prjobscanada.com</a>
            .
          </span>
        </p>
      </div>
    </div>
  );
}
