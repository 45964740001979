export const userConstants = {
    REGISTER: 'REGISTER',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    
    LOGOUT: 'LOGOUT',
    PROFILE_REQUEST: 'PROFILE_REQUEST',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_FAILURE: 'PROFILE_FAILURE',  
    
    PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
    PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE'    
};
