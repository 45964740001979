import React from "react";
import { useEffect } from "react";
export default function About() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="common-content">
      <div className="container">
        <h1>About Us</h1>
        <p>
          Welcome to PR Jobs Canada, a specialized online employment
          platform with a national reach. We are more than just a job portal; we
          are a bridge that connects newcomers and recent immigrants in Canada
          to their dreams and aspirations.
        </p>
        <p>
          Our dedication lies in assisting these individuals in securing
          suitable employment. We understand the challenges that come with
          moving to a new country, and we strive to make the transition smoother
          by providing a platform where job opportunities are readily
          accessible.
        </p>{" "}
        <p>
          Our mission is to collaborate with employers who are committed to
          integrating new immigrants into the Canadian workforce. We believe in
          the strength of diversity and the unique perspectives that immigrants
          bring to the table. By highlighting these employers, we hope to
          encourage more businesses to open their doors to the diverse talent
          pool that immigrants represent.
        </p>
        <p>
          At Nirvana Job Portal, we believe in the potential of every
          individual. We see beyond borders and cultural differences to the
          value and skills that each person brings to the Canadian workforce.
          Our job board, while tailored to a specific community, upholds the
          values of inclusivity and diversity.
        </p>{" "}
        <p>
          We invite all potential candidates across Canada, regardless of their
          background, to discover and apply for the job opportunities we offer.
          We are proud to provide a platform where everyone has an equal chance
          to succeed and contribute to the Canadian economy.
        </p>
        <p>
          Join us in our journey to create a welcoming and inclusive workforce
          in Canada. Together, we can build a stronger and more diverse
          community that benefits all its members.
        </p>
        <p>
          At PR Jobs Canada, your journey to fulfilling employment begins.
          Welcome aboard!
        </p>
      </div>
    </div>
  );
}
