export function authHeader() {
  // return authorization header with jwt token
  // let user = JSON.parse(localStorage.getItem('user'));

  // if (user && user.token) {
  //     return { 'Authorization': 'Bearer ' + user.token };
  // } else {
  //     return {};
  // }
  const token = btoa(`admin:1234`);
  return { Authorization: "Basic " + token };
}

export function authHeaderToken() {
  // Check if the user credentials are available

  // Encode the username and password in Base64 format
  const token = btoa(`admin:1234`);
  return { Authorization: "Basic " + token };
}
