import React from "react";
import Banner from "./Banner";
import GetStarted from "./GetStarted";
import JobService from "./JobService";
import JobCategory from "./JobCategory";
import Jobs from "./Jobs";
import BannerContent from "../../data/BannerContent.json";
import GetStartedContent from "../../data/GetStartedContent.json";
import JobServiceContent from "../../data/JobServiceContent.json";
import { useEffect } from "react";
const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner slider={BannerContent} />
      <GetStarted content={GetStartedContent} />
      <JobCategory />
      <JobService content={JobServiceContent} />
      <Jobs />
    </>
  );
};

export default Home;
