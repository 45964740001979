import React from "react";
import { ConsultantDashboard } from "./consultant/ConsultantDashboard";
import { JobSeekerDashboard } from "./jobseeker/JobSeekerDashboard";
import { currentUser } from "../../helpers";

export const CommonDashboard = () => {
  const user_type = currentUser().user_type;
  const isConsultantOrEmployer =
    user_type === "Consultants" || user_type === "Employers";
  const DashboardComponent = isConsultantOrEmployer ? (
    <ConsultantDashboard />
  ) : (
    <JobSeekerDashboard />
  );

  return (
    <>
      {DashboardComponent}
      <hr className="divider" />
    </>
  );
};
